import MenuTop from "../../components/SearchAll/Public/MenuTop";
import "../../css/Public/SearchAll.css";
import { ThemeContext, LanguageContext } from "../../App";
import React, { useState, useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import AdvancedSearch from "../../components/SearchAll/SearchTop/AdvancedSearch";
import ResultSearchAll from "../../components/SearchAll/ResultSearchAll";
import ScrollToTopButton from "../../components/SearchAll/Public/ScrollToTopButton";
// import SaveBtnBottom from "../../components/SearchAll/Public/SaveBtnBottom";
import SimpleSearchNew from "../../components/SearchAll/SearchTop/SimpleSearchNew";
import {
  base_url,
  twitter_post_search_url,
  post_search_url,
  instagram_post_search_url,
} from "../../backend/config_backend";
import axios from "axios";
import { getToken } from "../../backend/Login_backend";
import { getSessionInfo } from "../../backend/Session_Manager_backend";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomeMenu from "./HomeMenu";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";



function SearchAll({
  saveLoginStatus,
  setIsSubmitted,
  isSuperUser,
  isDefultDB,
  isDemoChecked,
}) {
  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  const { language } = useContext(LanguageContext);

  const [activeComponent, setActiveComponent] = useState("simple");
  const [showResults, setShowResults] = useState(false);

  const handleSimpleClick = () => {
    setActiveComponent("simple");
    setSearchWord("");
    setSearchOrWord("");
    setSearchNotWord("");
    setStartingDate("");
    setEndingDate("");
  };

  const handleAdvancedClick = () => {
    setActiveComponent("advanced");
    setSearchWord("");
    setSearchOrWord("");
    setSearchNotWord("");
    setStartingDate("");
    setEndingDate("");
  };

  

  // const handleFilterMakerClick = () => {
  //   setActiveComponent("filter_maker");
  //   setSearchWord("");
  //   setSearchOrWord("");
  //   setSearchNotWord("");
  //   setStartingDate("");
  //   setEndingDate("");
  // };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState(getTodayDate());
  const [searchWord, setSearchWord] = useState("");
  const [searchUserName, setSearchUserName] = useState("");
  const [searchOrWord, setSearchOrWord] = useState("");
  const [searchNotWord, setSearchNotWord] = useState("");
  const [searchfilter, setSearchfilter] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [searchHashtag, setsearchHashtag] = useState("");

  // this is data of twitter founded posts
  const [twitterPostByWordsData, setTwitterPostByWordsData] =
    useState(undefined);
  const [facebookPostData, setFacebookPostData] = useState(undefined);
  const [InstaPostData, setInstaPostData] = useState(undefined);
  const [googlePostData, setGooglePostData] = useState(undefined);

  const [needLogin, setneedLogin] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [fetchedApi, setfetchedApi] = useState(false);
  const [username, setUsername] = useState("");

  const [loadingStateFB, setLoadingStateFB] = useState(false);
  const [loadingStateTW, setLoadingStateTW] = useState(false);
  const [loadingStateIN, setLoadingStateIN] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingStateGO, setLoadingStateGO] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [selectedRankingParameter, setSelectedRankingParameter] =
    useState("delta_time");

  // btn choose socials

  const [isTwitterActive, setIsTwitterActive] = useState(true);
  const [isFacebookActive, setIsFacebookActive] = useState(true);
  const [isInstagramActive, setIsInstagramActive] = useState(true);
  const [isWebSiteActive, setIsWebSiteActive] = useState(true);

  // جلوگیری از ارسال درخواست مکرر
  const [isTwitterRequesting, setIsTwitterRequesting] = useState(false);
  const [isFacebookRequesting, setIsFacebookRequesting] = useState(false);
  const [isInstagramRequesting, setIsInstagramRequesting] = useState(false);
  // const [isWebSiteRequesting, setIsWebSiteRequesting] = useState(false);

  const [showTwitterSection, setShowTwitterSection] = useState(false);
  const [showFacebookSection, setShowFacebookSection] = useState(false);
  const [showInstagramSection, setShowInstagramSection] = useState(false);

  const handleStart = async () => {
    try {
      let result = await getSessionInfo();
      console.log("Got Session Info ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        console.log("Session info: ", result);
        let data = result.data.data;
        setUsername(data.username);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleStart();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedApi]);

  // filter by click

  const [clickedUsername, setClickedUsername] = useState("");
  const [clickedWord, setClickedWord] = useState([]);
  const [clickedDate, setClickedDate] = useState("");
  const [clickedMainSubject, setClickedMainSubject] = useState("");
  const [clickedSubSubject, setClickedSubSubject] = useState("");
  const [clickedAxis, setClickedAxis] = useState("");
  const [clickedEmotion, setClickedEmotion] = useState("");
  const [clickedActionState, setClickedActionState] = useState("");

  const handleWordFilterClick = async (wordClick, usernameFilter) => {
    const updatedClickedWord = [...clickedWord, wordClick];
    setClickedWord(updatedClickedWord);
    handleSearchClick(updatedClickedWord.join(" - "), usernameFilter);
    console.log("Clicked Words:", updatedClickedWord);
  };

  const handleUsernameClick = (usernameFilter, wordClick) => {
    setClickedUsername(usernameFilter);
    setSearchUserName(usernameFilter);
    handleSearchClick(wordClick, usernameFilter);
    console.log("this is user name", usernameFilter);
  };

  const handleMainSubjectClick = (mainSubject, wordClick, usernameFilter) => {
    setClickedMainSubject(mainSubject);
    handleSearchClick(wordClick, usernameFilter, mainSubject);
    console.log("this is mainSubject", mainSubject);
  };

  const handleSubSubjectClick = (
    subSubject,
    parentName,
    wordClick,
    usernameFilter
  ) => {
    const message = `You clicked on the subSubject ${subSubject} of mainSubject ${parentName}.`;
    console.log(message);
    setClickedMainSubject(parentName);
    setClickedSubSubject(subSubject);
    handleSearchClick(wordClick, usernameFilter, parentName, subSubject);
  };

  const handleFilterAxisClick = (
    filterAxis,
    wordClick,
    usernameFilter,
    mainSubject,
    subSubject
  ) => {
    setClickedAxis(filterAxis);
    handleSearchClick(
      wordClick,
      usernameFilter,
      mainSubject,
      subSubject,
      filterAxis
    );
    console.log("this is filterAxis", filterAxis);
  };

  const handleFilterEmotionClick = (
    filterEmotion,
    wordClick,
    usernameFilter,
    mainSubject,
    subSubject,
    filterAxis
  ) => {
    setClickedEmotion(filterEmotion);
    handleSearchClick(
      wordClick,
      usernameFilter,
      mainSubject,
      subSubject,
      filterAxis,
      filterEmotion
    );
    console.log("this is filterEmotion", filterEmotion);
  };

  const handleFilterActionClick = (
    filterActionState,
    filterEmotion,
    wordClick,
    usernameFilter,
    mainSubject,
    subSubject,
    filterAxis
  ) => {
    setClickedActionState(filterActionState);
    handleSearchClick(
      wordClick,
      usernameFilter,
      mainSubject,
      subSubject,
      filterAxis,
      filterEmotion,
      filterActionState
    );
    console.log("this is filterEmotion", filterEmotion);
  };

  const [dateClicked, setDateClicked] = useState(false);

  useEffect(() => {
    if (dateClicked) {
      handleTwitterPost(searchWord, searchUserName);
      handleInstagramPost(searchWord, searchUserName);
      handleFacebookPost(searchWord, searchUserName);
      setDateClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateClicked]);

  const handleDateClickFilter = (dateFilter) => {
    console.log("this is date", dateFilter);
    setStartingDate(dateFilter);
    setEndingDate(dateFilter);
    setClickedDate(dateFilter);
    setDateClicked(true);
  };

  const moment = require("moment");

  // request all data

  const handleTwitterPost = async (
    wordClick,
    usernameFilter,
    mainSubject,
    subSubject,
    filterAxis,
    filterEmotion,
    filterActionState
  ) => {
    if (isTwitterActive && !isTwitterRequesting) {
      try {
        setLoadingStateTW(true);
        setIsTwitterRequesting(true);
        // تاریخ امروز
        const today = moment().format("YYYY-MM-DD");

        // تاریخ یک ماه پیش
        const oneMonthAgo = moment().subtract(1, "month").format("YYYY-MM-DD");
        const oneYearAgo = moment().subtract(1, "year").format("YYYY-MM-DD");

        let start;
        if (isDefultDB === "earth_link") {
          start = startingDate.length > 0 ? startingDate : oneYearAgo;
        } else {
          start = startingDate.length > 0 ? startingDate : oneMonthAgo;
        }
        let end = endingDate.length > 0 ? endingDate : today;

        let word;
        if (searchWord && searchWord.length > 0) {
          word = searchWord;
        } else if (wordClick && wordClick.length > 0) {
          word = wordClick;
        } else {
          word = "";
        }

        let usernames;

        if (searchUserName && searchUserName.length > 0) {
          usernames = searchUserName;
        } else if (usernameFilter && usernameFilter.length > 0) {
          usernames = usernameFilter;
        } else {
          usernames = "";
        }

        let orWord = searchOrWord.length > 0 ? searchOrWord : "";
        let notWord = searchNotWord.length > 0 ? searchNotWord : "";
        let filter = searchfilter;
        let hashtag = searchHashtag.length > 0 ? searchHashtag : "";
        let filter_main_subject = mainSubject || "";
        let filter_sub_subject = subSubject || "";
        let filter_axis = filterAxis || "";
        let filter_emotion = filterEmotion || "";
        let filter_action_state = filterActionState || "";
        let orderBy =
          selectedRankingParameter.length > 0
            ? selectedRankingParameter
            : "delta_time";

        // console.log(start, end, word, hashtag);

        let hasError = false;
        let status = 200;

        let errorText = "";
        let data = undefined;
        let url = base_url + twitter_post_search_url;
        await axios
          .get(url, {
            params: {
              word,
              usernames,
              orWord,
              notWord,
              startDate: start,
              endDate: end,
              hashtag,
              orderBy,
              filter,
              filter_main_subject,
              filter_sub_subject,
              filter_axis,
              filter_emotion,
              filter_action_state,
            },
            withCredentials: true,
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
              Authorization: "Bearer " + String(getToken().access),
            },
          })

          .then(function (response) {
            if (response) data = response;
          })
          .catch(function (error) {
            console.log(error);
            let response = error.response;
            errorText = response.request.response;

            hasError = true;
            status = response.status;
            if (response.status === 500) {
              errorText = "Server Error ... ";
            } else if (
              errorText.includes("Authentication") ||
              errorText.includes("credentials")
            ) {
            } else if (
              response.status === 403 &&
              errorText.includes("Account is Expired")
            ) {
              if (language === "en") {
                Swal.fire({
                  text: "Your account has expired. Redirecting to profile...",
                  icon: "error",
                  timer: 5000, // 5 seconds
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {
                  window.location.href = "/profile";
                });
              } else {
                Swal.fire({
                  text: "حسابك قد انتهت. جار التوجيه إلى الملف الشخصي...",
                  icon: "error",
                  timer: 5000, // 5 ثانیه
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {
                  window.location.href = "/profile";
                });
              }
            }
          });
        let postsByWord = { hasError, errorText, data, status };
        if (postsByWord.hasError) {
          if (postsByWord.isLoggedIn === false) {
            setneedLogin(true);
          } else if (postsByWord.status === 500) {
            if (language === "en") {
              toast.error("Server Error");
            } else {
              toast.error("هناك خطأ في الاتصال");
            }
          } else {
            if (language === "en") {
              toast.error("Connection Error");
            } else {
              toast.error("هناك خطأ في الاتصال");
            }
          }
        } else {
          // console.log(postsByWord);
          data = postsByWord.data.data;
          setTwitterPostByWordsData(data);
          console.log("Twiiter : postsByWord data.data ", data);
          setShowTwitterSection(true);
          if (data.next_link !== null) {
            setLoadingStateTW(false);
          } else {
            setLoadingStateTW(false);
          }
        }
        setIsTwitterRequesting(false);
      } catch (error) {
        if (language === "en") {
          toast.error("Something Went Wrong");
        } else {
          toast.error("لقد حدث خطأ ما");
        }
        setIsTwitterRequesting(false);
      }
    }
  };

  const handleInstagramPost = async (
    wordClick,
    usernameFilter,
    mainSubject,
    subSubject,
    filterAxis,
    filterEmotion,
    filterActionState
  ) => {
    if (isInstagramActive && !isInstagramRequesting) {
      try {
        setLoadingStateIN(true);
        setIsInstagramRequesting(true);

        // تاریخ امروز
        const today = moment().format("YYYY-MM-DD");

        // تاریخ یک ماه پیش
        const oneMonthAgo = moment().subtract(1, "month").format("YYYY-MM-DD");
        const oneYearAgo = moment().subtract(1, "year").format("YYYY-MM-DD");

        // let start = startingDate.length > 0 ? startingDate : oneMonthAgo;

        let start;
        if (isDefultDB === "earth_link") {
          start = startingDate.length > 0 ? startingDate : oneYearAgo;
        } else {
          start = startingDate.length > 0 ? startingDate : oneMonthAgo;
        }
        // start = "2023-09-01";
        let end = endingDate.length > 0 ? endingDate : today;
        // let word = searchWord.length > 0 ? searchWord : "";
        // let word = (searchWord.length > 0 ? searchWord : "") || wordClick;
        let word;
        if (searchWord && searchWord.length > 0) {
          word = searchWord;
        } else if (wordClick && wordClick.length > 0) {
          word = wordClick;
        } else {
          word = "";
        }

        let usernames;

        if (searchUserName && searchUserName.length > 0) {
          usernames = searchUserName;
        } else if (usernameFilter && usernameFilter.length > 0) {
          usernames = usernameFilter;
        } else {
          usernames = "";
        }

        let orWord = searchOrWord.length > 0 ? searchOrWord : "";
        let notWord = searchNotWord.length > 0 ? searchNotWord : "";
        let hashtag = searchHashtag.length > 0 ? searchHashtag : "";
        let filter_main_subject = mainSubject || "";
        let filter_sub_subject = subSubject || "";
        let filter_axis = filterAxis || "";
        let filter_emotion = filterEmotion || "";
        let filter_action_state = filterActionState || "";

        let filter = searchfilter;
        let orderBy =
          selectedRankingParameter.length > 0
            ? selectedRankingParameter
            : "delta_time";

        // console.log(start, end, word, hashtag);

        let hasError = false;
        let status = 200;

        let errorText = "";
        let data = undefined;
        let url = base_url + instagram_post_search_url;
        await axios
          .get(url, {
            params: {
              word,
              usernames,
              orWord,
              notWord,
              startDate: start,
              endDate: end,
              hashtag,
              orderBy,
              filter,
              filter_main_subject,
              filter_sub_subject,
              filter_axis,
              filter_emotion,
              filter_action_state,
            },
            withCredentials: true,
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
              Authorization: "Bearer " + String(getToken().access),
            },
          })

          .then(function (response) {
            if (response) data = response;
          })
          .catch(function (error) {
            console.log(error);
            let response = error.response;
            errorText = response.request.response;

            hasError = true;
            status = response.status;
            if (response.status === 500) {
              errorText = "Server Error ... ";
            } else if (
              errorText.includes("Authentication") ||
              errorText.includes("credentials")
            ) {
            } else if (
              response.status === 403 &&
              errorText.includes("Account is Expired")
            ) {
              if (language === "en") {
                Swal.fire({
                  text: "Your account has expired. Redirecting to profile...",
                  icon: "error",
                  timer: 5000, // 5 seconds
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {
                  window.location.href = "/profile";
                });
              } else {
                Swal.fire({
                  text: "حسابك قد انتهت. جار التوجيه إلى الملف الشخصي...",
                  icon: "error",
                  timer: 5000, // 5 ثانیه
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {
                  window.location.href = "/profile";
                });
              }
            }
          });
        let postsByWord = { hasError, errorText, data, status };
        if (postsByWord.hasError) {
          if (postsByWord.isLoggedIn === false) {
            setneedLogin(true);
          } else if (postsByWord.status === 500) {
            if (language === "en") {
              toast.error("Server Error");
            } else {
              toast.error("هناك خطأ في الاتصال");
            }
          } else {
            if (language === "en") {
              toast.error("Connection Error");
            } else {
              toast.error("هناك خطأ في الاتصال");
            }
          }
        } else {
          // console.log(postsByWord);
          data = postsByWord.data.data;
          setInstaPostData(data);
          console.log("Instagram : postsByWord data.data ", data);
          setShowInstagramSection(true);
          if (data.next_link !== null) {
            setLoadingStateIN(false);
          } else {
            setLoadingStateIN(false);
          }
        }
        setIsInstagramRequesting(false);
      } catch (error) {
        if (language === "en") {
          toast.error("Something Went Wrong");
        } else {
          toast.error("لقد حدث خطأ ما");
        }
        setIsInstagramRequesting(false);
      }
    }
  };

  const handleFacebookPost = async (
    wordClick,
    usernameFilter,
    mainSubject,
    subSubject,
    filterAxis,
    filterEmotion,
    filterActionState
  ) => {
    if (isFacebookActive && !isFacebookRequesting) {
      try {
        setLoadingStateFB(true);
        setIsFacebookRequesting(true);

        const today = moment().format("YYYY-MM-DD");

        const oneMonthAgo = moment().subtract(1, "month").format("YYYY-MM-DD");
        const oneYearAgo = moment().subtract(1, "year").format("YYYY-MM-DD");

        let start;
        if (isDefultDB === "earth_link") {
          start = startingDate.length > 0 ? startingDate : oneYearAgo;
        } else {
          start = startingDate.length > 0 ? startingDate : oneMonthAgo;
        }

        // let start = startingDate.length > 0 ? startingDate : oneMonthAgo;
        // start = "2023-09-01";
        let end = endingDate.length > 0 ? endingDate : today;
        // let word = searchWord.length > 0 ? searchWord : "";
        // let word = (searchWord.length > 0 ? searchWord : "") || wordClick;
        let word;
        if (searchWord && searchWord.length > 0) {
          word = searchWord;
        } else if (wordClick && wordClick.length > 0) {
          word = wordClick;
        } else {
          word = "";
        }

        console.log("searchWord", searchWord);

        let usernames;

        if (searchUserName && searchUserName.length > 0) {
          usernames = searchUserName;
        } else if (usernameFilter && usernameFilter.length > 0) {
          usernames = usernameFilter;
        } else {
          usernames = "";
        }

        let orWord = searchOrWord.length > 0 ? searchOrWord : "";
        let notWord = searchNotWord.length > 0 ? searchNotWord : "";
        let hashtag = searchHashtag.length > 0 ? searchHashtag : "";
        let filter_main_subject = mainSubject || "";
        let filter_sub_subject = subSubject || "";
        let filter_axis = filterAxis || "";
        let filter_emotion = filterEmotion || "";
        let filter_action_state = filterActionState || "";

        let filter = searchfilter;
        let orderBy =
          selectedRankingParameter.length > 0
            ? selectedRankingParameter
            : "delta_time";

        let hasError = false;
        let status = 200;

        let errorText = "";
        let data = undefined;
        let url = base_url + post_search_url;
        await axios
          .get(url, {
            params: {
              word,
              usernames,
              orWord,
              notWord,
              startDate: start,
              endDate: end,
              hashtag,
              orderBy,
              filter,
              filter_main_subject,
              filter_sub_subject,
              filter_axis,
              filter_emotion,
              filter_action_state,
            },
            withCredentials: true,
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
              Authorization: "Bearer " + String(getToken().access),
            },
          })
          .then(function (response) {
            if (response) data = response;
          })
          .catch(function (error) {
            console.log(error);
            let response = error.response;
            errorText = response.request.response;

            hasError = true;
            status = response.status;
            if (response.status === 500) {
              errorText = "Server Error ... ";
            } else if (
              errorText.includes("Authentication") ||
              errorText.includes("credentials")
            ) {
            } else if (
              response.status === 403 &&
              errorText.includes("Account is Expired")
            ) {
              if (language === "en") {
                Swal.fire({
                  text: "Your account has expired. Redirecting to profile...",
                  icon: "error",
                  timer: 5000, // 5 seconds
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {
                  window.location.href = "/profile";
                });
              } else {
                Swal.fire({
                  text: "حسابك قد انتهت. جار التوجيه إلى الملف الشخصي...",
                  icon: "error",
                  timer: 5000, // 5 ثانیه
                  timerProgressBar: true,
                  showConfirmButton: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {
                  window.location.href = "/profile";
                });
              }
            }
          });

        let postsByWord = { hasError, errorText, data, status };
        if (postsByWord.hasError) {
          if (postsByWord.isLoggedIn === false) {
            setneedLogin(true);
          } else if (postsByWord.status === 500) {
            if (language === "en") {
              toast.error("Server Error");
            } else {
              toast.error("هناك خطأ في الاتصال");
            }
          } else {
            if (language === "en") {
              toast.error("Connection Error");
            } else {
              toast.error("هناك خطأ في الاتصال");
            }
          }
        } else {
          data = postsByWord.data.data;
          setFacebookPostData(data);
          console.log("Facebook: postsByWord data.data ", data);
          setShowFacebookSection(true);
          if (data.next_link !== null) {
            setLoadingStateFB(false);
          } else {
            setLoadingStateFB(false);
          }
        }
        setIsFacebookRequesting(false);
      } catch (error) {
        if (language === "en") {
          toast.error("Something Went Wrong");
        } else {
          toast.error("لقد حدث خطأ ما");
        }
        setIsFacebookRequesting(false);
      }
    }
  };

  
  const handleSearchClick = (
    word,
    usernames,
    mainSubject,
    subSubject,
    filterAxis,
    filterEmotion,
    filterActionState
  ) => {
    // setSearchWord("");
    handleTwitterPost(
      word,
      usernames,
      mainSubject,
      subSubject,
      filterAxis,
      filterEmotion,
      filterActionState
    );
    handleFacebookPost(
      word,
      usernames,
      mainSubject,
      subSubject,
      filterAxis,
      filterEmotion,
      filterActionState
    );
    handleInstagramPost(
      word,
      usernames,
      mainSubject,
      subSubject,
      filterAxis,
      filterEmotion,
      filterActionState
    );
    setShowResults(true);
  };

  // const handleGooglePost = async () => {
  //   if (isWebSiteActive && !isWebSiteRequesting) {
  //     try {
  //       setLoadingStateGO(true);
  //       setIsWebSiteRequesting(true);

  //       let start = startingDate.length > 0 ? startingDate : "2000-06-01";
  //       let end = endingDate.length > 0 ? endingDate : "2040-06-01";
  //       let word = searchWord.length > 0 ? searchWord : "";
  //       let orWord = searchOrWord.length > 0 ? searchOrWord : "";
  //       let notWord = searchNotWord.length > 0 ? searchNotWord : "";
  //       let hashtag = searchHashtag.length > 0 ? searchHashtag : "";
  //       let orderBy =
  //         selectedRankingParameter.length > 0
  //           ? selectedRankingParameter
  //           : "delta_time";

  //       let hasError = false;
  //       let status = 200;

  //       let errorText = "";
  //       let data = undefined;
  //       let url = base_url + web_search_post_search_url;
  //       await axios
  //         .get(url, {
  //           params: {
  //             word,
  //             orWord,
  //             notWord,
  //             startDate: start,
  //             endDate: end,
  //             hashtag,
  //             orderBy,
  //           },
  //           withCredentials: true,
  //           headers: {
  //             "Access-Control-Allow-Origin": "*",
  //             "Access-Control-Allow-Headers": "*",
  //             Authorization: "Bearer " + String(getToken().access),
  //           },
  //         })
  //         .then(function (response) {
  //           if (response) data = response;
  //         })
  //         .catch(function (error) {
  //           console.log(error);
  //           let response = error.response;
  //           errorText = response.request.response;

  //           hasError = true;
  //           status = response.status;
  //           if (response.status === 500) {
  //             errorText = "Server Error ... ";
  //           } else if (
  //             errorText.includes("Authentication") ||
  //             errorText.includes("credentials")
  //           ) {
  //           }
  //         });

  //       let postsByWord = { hasError, errorText, data, status };
  //       if (postsByWord.hasError) {
  //         if (postsByWord.isLoggedIn === false) {
  //           setneedLogin(true);
  //         } else if (postsByWord.status === 500) {
  //           toast.error("Server Error");
  //         } else {
  //           toast.error("Connection Error");
  //         }
  //       } else {
  //         data = postsByWord.data.data;
  //         setGooglePostData(data);
  //         console.log("Google: postsByWord data.data ", data);

  //         if (data.next_link !== null) {
  //           setLoadingStateGO(false);
  //         } else {
  //           setLoadingStateGO(false);
  //         }
  //       }
  //       setIsWebSiteRequesting(false);
  //     } catch (error) {
  //       toast.error("Something Went Wrong");
  //       setIsWebSiteRequesting(false);
  //     }
  //   }
  // };

  const [selectedMenuItem, setSelectedMenuItem] = useState(0);

  // filter data by onclick
  const [clearSearch, setClearSearch] = useState(false);

  useEffect(() => {
    if (clearSearch) {
      handleSearchClick("");
      setClearSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSearch]);

  const handleWordFilterClear = (wordToRemove) => {
    const updatedClickedWord = clickedWord.filter(
      (word) => word !== wordToRemove
    );
    setClickedWord(updatedClickedWord);
    handleSearchClick(updatedClickedWord.join(" - "));
  };

  const handleUsernameClear = () => {
    setSearchUserName("");
    setClickedUsername("");
    setClearSearch(true);
  };

  const handleDateFilterClear = () => {
    setStartingDate("");
    setEndingDate("");
    setClickedDate("");
    setDateClicked(true);
    console.log("handleDateFilterClear clicked");
  };

  const handleMainSubjectFilterClear = () => {
    setClickedMainSubject("");
    handleSearchClick(searchWord, searchUserName);
  };

  const handleSubSubjectFilterClear = () => {
    setClickedSubSubject("");
    setClickedMainSubject("");
    handleSearchClick(searchWord, searchUserName);
  };

  const handleAxisFilterClear = () => {
    setClickedAxis("");
    handleSearchClick(searchWord, searchUserName);
  };

  const handleEmotionFilterClear = () => {
    setClickedEmotion("");
    handleSearchClick(searchWord, searchUserName);
  };

  const handleActionStateFilterClear = () => {
    setClickedActionState("");
    handleSearchClick(searchWord, searchUserName);
  };

  return (
    <div className="search_all">
      {needLogin ? <Navigate to="/" /> : null}
      <div
        className={`search_all_page ${
          language === "ar" ? "search_all_page_ar" : ""
        } ${theme === "dark" ? "dark_mode" : ""}`}
      >
        <HomeMenu
          saveLoginStatus={saveLoginStatus}
          setIsSubmitted={setIsSubmitted}
          isSuperUser={isSuperUser}
          isDefultDB={isSuperUser}
          isDemoChecked={isDemoChecked}
        />
        <div
          className={`body_search_all ${theme === "dark" ? "dark_mode" : ""}`}
        >
          <div className="header_search_all">
            <MenuTop
              onSelectMenuItem={setSelectedMenuItem}
              selectedMenuItem={selectedMenuItem}
              username={username}
              saveLoginStatus={saveLoginStatus}
              setIsSubmitted={setIsSubmitted}
            />
          </div>
          <div className="search_section_search">
            {language === "en" ? (
              <div className="search_section_search_btn">
                <button
                  className={activeComponent === "advanced" ? "active" : ""}
                  onClick={handleAdvancedClick}
                >
                  Advanced search
                </button>
                <button
                  className={activeComponent === "simple" ? "active" : ""}
                  onClick={handleSimpleClick}
                >
                  Simple search
                </button>
              </div>
            ) : (
              <div className="search_section_search_btn_ar">
                <button
                  className={activeComponent === "simple" ? "active" : ""}
                  onClick={handleSimpleClick}
                >
                  البحث البسيط
                </button>
                <button
                  className={activeComponent === "advanced" ? "active" : ""}
                  onClick={handleAdvancedClick}
                >
                  البحث المتقدم
                </button>
              </div>
            )}

            {language === "en" ? (
              <div
                className={`simple_search_home ${
                  activeComponent === "simple"
                    ? "simple_active"
                    : "advanced_active"
                }`}
              >
                <div
                  className={`search_btn_searchall ${
                    activeComponent === "simple"
                      ? "search_btn_searchall_simple_active"
                      : "search_btn_searchall_advanced_active"
                  }`}
                >
                  <button onClick={handleSearchClick}>Search</button>
                </div>
                {activeComponent === "simple" && (
                  <SimpleSearchNew
                    selectedMenuItem={selectedMenuItem}
                    setSelectedMenuItem={setSelectedMenuItem}
                    setSearchWord={setSearchWord}
                    isTwitterActive={isTwitterActive}
                    setIsTwitterActive={setIsTwitterActive}
                    isFacebookActive={isFacebookActive}
                    setIsFacebookActive={setIsFacebookActive}
                    setStartingDate={setStartingDate}
                    setEndingDate={setEndingDate}
                    isInstagramActive={isInstagramActive}
                    setIsInstagramActive={setIsInstagramActive}
                    isWebSiteActive={isWebSiteActive}
                    setIsWebSiteActive={setIsWebSiteActive}
                  />
                )}
                {activeComponent === "advanced" && (
                  <AdvancedSearch
                    selectedMenuItem={selectedMenuItem}
                    setSelectedMenuItem={setSelectedMenuItem}
                    setSearchWord={setSearchWord}
                    setSearchOrWord={setSearchOrWord}
                    setSearchNotWord={setSearchNotWord}
                    isTwitterActive={isTwitterActive}
                    setIsTwitterActive={setIsTwitterActive}
                    isFacebookActive={isFacebookActive}
                    setIsFacebookActive={setIsFacebookActive}
                    isInstagramActive={isInstagramActive}
                    setStartingDate={setStartingDate}
                    setEndingDate={setEndingDate}
                    setIsInstagramActive={setIsInstagramActive}
                    isWebSiteActive={isWebSiteActive}
                    setIsWebSiteActive={setIsWebSiteActive}
                    setSearchfilter={setSearchfilter}
                  />
                )}
              </div>
            ) : (
              <div
                className={`simple_search_home ${
                  activeComponent === "simple"
                    ? "simple_active_ar"
                    : "advanced_active_ar"
                }`}
              >
                <div
                  className={`search_btn_searchall ${
                    activeComponent === "simple"
                      ? "search_btn_searchall_simple_active"
                      : "search_btn_searchall_advanced_active_ar"
                  }`}
                >
                  <button onClick={handleSearchClick}>بحث</button>
                </div>
                {activeComponent === "simple" && (
                  <SimpleSearchNew
                    selectedMenuItem={selectedMenuItem}
                    setSelectedMenuItem={setSelectedMenuItem}
                    setSearchWord={setSearchWord}
                    isTwitterActive={isTwitterActive}
                    setIsTwitterActive={setIsTwitterActive}
                    isFacebookActive={isFacebookActive}
                    setIsFacebookActive={setIsFacebookActive}
                    setStartingDate={setStartingDate}
                    setEndingDate={setEndingDate}
                    isInstagramActive={isInstagramActive}
                    setIsInstagramActive={setIsInstagramActive}
                    isWebSiteActive={isWebSiteActive}
                    setIsWebSiteActive={setIsWebSiteActive}
                  />
                )}
                {activeComponent === "advanced" && (
                  <AdvancedSearch
                    selectedMenuItem={selectedMenuItem}
                    setSelectedMenuItem={setSelectedMenuItem}
                    setSearchWord={setSearchWord}
                    setSearchOrWord={setSearchOrWord}
                    setSearchNotWord={setSearchNotWord}
                    isTwitterActive={isTwitterActive}
                    setIsTwitterActive={setIsTwitterActive}
                    isFacebookActive={isFacebookActive}
                    setIsFacebookActive={setIsFacebookActive}
                    isInstagramActive={isInstagramActive}
                    setStartingDate={setStartingDate}
                    setEndingDate={setEndingDate}
                    setIsInstagramActive={setIsInstagramActive}
                    isWebSiteActive={isWebSiteActive}
                    setIsWebSiteActive={setIsWebSiteActive}
                    setSearchfilter={setSearchfilter}
                  />
                )}
              </div>
            )}
          </div>
          <div className="show_filters_header_search_all">
            {clickedUsername && (
              <div className="items_show_filters_header_search_all">
                {clickedUsername}
                <CloseIcon
                  className="close_items_show_filters_header_search_all"
                  onClick={handleUsernameClear}
                />
              </div>
            )}
            {clickedWord &&
              clickedWord.map((word, index) => (
                <div
                  key={index}
                  className="items_show_filters_header_search_all"
                >
                  {word}

                  <CloseIcon
                    className="close_items_show_filters_header_search_all"
                    onClick={() => handleWordFilterClear(word)}
                  />
                </div>
              ))}

            {clickedDate && (
              <div className="items_show_filters_header_search_all">
                {clickedDate}
                <CloseIcon
                  className="close_items_show_filters_header_search_all"
                  onClick={handleDateFilterClear}
                />
              </div>
            )}
            {clickedMainSubject && (
              <div className="items_show_filters_header_search_all">
                {clickedMainSubject}
                <CloseIcon
                  className="close_items_show_filters_header_search_all"
                  onClick={handleMainSubjectFilterClear}
                />
              </div>
            )}
            {clickedSubSubject && (
              <div className="items_show_filters_header_search_all">
                {clickedSubSubject}
                <CloseIcon
                  className="close_items_show_filters_header_search_all"
                  onClick={handleSubSubjectFilterClear}
                />
              </div>
            )}
            {clickedAxis && (
              <div className="items_show_filters_header_search_all">
                {clickedAxis}
                <CloseIcon
                  className="close_items_show_filters_header_search_all"
                  onClick={handleAxisFilterClear}
                />
              </div>
            )}
            {clickedEmotion && (
              <div className="items_show_filters_header_search_all">
                {clickedEmotion}
                <CloseIcon
                  className="close_items_show_filters_header_search_all"
                  onClick={handleEmotionFilterClear}
                />
              </div>
            )}
            {clickedActionState && (
              <div className="items_show_filters_header_search_all">
                {clickedActionState}
                <CloseIcon
                  className="close_items_show_filters_header_search_all"
                  onClick={handleActionStateFilterClear}
                />
              </div>
            )}
          </div>

          <div className="tabs_search_all">
            {showResults && (
              <ResultSearchAll
                twitterPosts={twitterPostByWordsData}
                setTwitterPosts={setTwitterPostByWordsData}
                facebookPosts={facebookPostData}
                setFacebookPosts={setFacebookPostData}
                isTwitterActive={isTwitterActive}
                isFacebookActive={isFacebookActive}
                loadingStateFB={loadingStateFB}
                loadingStateTW={loadingStateTW}
                isInstagramActive={isInstagramActive}
                loadingStateIN={loadingStateIN}
                instagramPosts={InstaPostData}
                setInstagramPosts={setInstaPostData}
                isWebSiteActive={isWebSiteActive}
                setGooglePostData={setGooglePostData}
                googlePosts={googlePostData}
                loadingStateGO={loadingStateGO}
                isDefultDB={isDefultDB}
                startingDate={startingDate}
                endingDate={endingDate}
                // filter by onclick
                onUsernameClick={handleUsernameClick}
                onWordClick={handleWordFilterClick}
                searchWord={searchWord}
                searchHashtag={searchHashtag}
                searchOrWord={searchOrWord}
                searchNotWord={searchNotWord}
                searchfilter={searchfilter}
                onDateClick={handleDateClickFilter}
                onMainSubjectClick={handleMainSubjectClick}
                onSubSubjectClick={handleSubSubjectClick}
                onAxisClick={handleFilterAxisClick}
                onEmotionClick={handleFilterEmotionClick}
                onActionClick={handleFilterActionClick}
                showTwitterSection={showTwitterSection}
                showInstagramSection={showInstagramSection}
                showFacebookSection={showFacebookSection}
                clickedEmotion={clickedEmotion}
                clickedActionState={clickedActionState}
                clickedAxis={clickedAxis}
                clickedSubSubject={clickedSubSubject}
                clickedMainSubject={clickedMainSubject}
              />
            )}
          </div>
          <div className="btns_bottom_page_searchall">
            <ScrollToTopButton />
            {/* <SaveBtnBottom /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchAll;
